(function($){
  var modals = 'section.fastaction-modal';
  var close  = 'a.fa-modal-close';
  var active = 'is-active';

  function checkLogin(){
    return $.ajax({
      url: '/api/v1/users',
      dataType: 'json',
      cache: false
    }).then(function (data){
      if (!data.id) { return data; }
      $(modals).removeClass(active);
      if (window.location.href.indexOf('/#') < 0){
        window.location.href = "/profile";
      } else {
        window.location.href = "#/profile";
        window.location.reload();
      }
    });
  }

  $('a[data-popup]').unbind();

  $('body').
  on('click', 'a#logout', function (e){
    e.preventDefault();
    e.stopPropagation();
    $.ajax({
      dataType: 'jsonp',
      cache: false,
      url: '/logout'
    }).then(function (data){
      if(data && data.length && Array.isArray(data) && data[0].indexOf('https://') === 0) {
        window.location.href = data[0];
      } else {
        window.location.href = '/';
      }
    });
  }).
  on('click', 'a[data-popup]', function (e){
    e.preventDefault();
    var data = $(this).data(), popup, pop = {
      left:   0,
      top:    0,
      width:  data.width  || 1000,
      height: data.height ||  640
    };

    if (screen && screen.width && screen.height) {
      pop.top  = ~~((screen.height/2) - (pop.height/2));
      pop.left = ~~((screen.width/2)  - (pop.width/2));
    }

    pop = 'toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,' + $.param(pop).replace(/&/g,',');
    popup = window.open(this.href, 'authProvider', pop);

    (function checkPopup(){
      if (!popup || popup.closed) { return checkLogin() }
      setTimeout(checkPopup, 1000/60);
    })();
  }).
  on('click', 'a[target="modal"]', function (e){ e.preventDefault();
    $(modals).not(this.hash).removeClass(active);
    $(this.hash).addClass(active).focus();
  }).
  on('click', close, function (e){ e.preventDefault();
    $(this).closest(modals).removeClass(active);
  }).
  on('keyup', function (e){
    if (e.keyCode === 27) {
      $(modals+'.'+active).removeClass(active);
    }
  });
}(jQuery));
