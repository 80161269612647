/* global Typekit, ga */
//load special font
try {
    Typekit.load();
} catch (e) {}

//Special validation method for profile credit card expiration month
window.checkExpirationMonth = function(field, rules, i, options) {
    /* jshint eqeqeq: false */
    var minMonth = new Date().getMonth() + 1;
    var minYear = new Date().getFullYear();
    if ("20" + $('[name="ExpirationYear"]').val() == minYear && field.val() < minMonth) {
        return "Expired month";
    }
};

window.checkExpirationDate = function(field, rules, i, options) {
    var enteredDate = new Date(field.val());
    var minDate = new Date();
    var minMonth = minDate.getMonth();
    var minYear = minDate.getFullYear();

    if (isNaN(enteredDate.getTime()) ||
        (enteredDate.getMonth() < minMonth && enteredDate.getFullYear() <= minYear)) {
        return 'Invalid date';
    }
};

String.prototype.luhnCheck = function() {
    var luhnArr = [
            [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        ],
        sum = 0;
    this.replace(/\D+/g, "").replace(/[\d]/g, function(c, p, o) {
        sum += luhnArr[(o.length - p) & 1][parseInt(c, 10)];
    });
    return (sum % 10 === 0) && (sum > 0);
};

//google analytics
(function(i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function() {
        (i[r].q = i[r].q || []).push(arguments);
    }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
ga('create', 'UA-28243511-7', 'ngpvan.com');
ga('send', 'pageview');

//scroll to section process page
$(document).on("click.sticky", 'a[href*=#]:not([href=#])', function(event) {
    event.preventDefault();
    $(window).scroll();
    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') || location.hostname === this.hostname) {
        var target = $(this.hash),
            headerHeight = $(".app-header").height() + 5; // Get fixed header height

        target = target.length ? target : $('#' + this.hash.slice(1));

        if (target.length) {
            $('html,body').animate({
                scrollTop: target.offset().top - headerHeight
            }, 300);
            return false;
        }
    }
});