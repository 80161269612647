/* Controllers */
(function (angular) {

  'use strict';

  function assignUserOnScope(scope) {
    return function (newScope) {
      _.assign(scope, newScope);
    };
  }

  angular.module('fastActionApp.controllers', []).
    controller('FrontController', function ($scope, $http, $location, $anchorScroll, userService) {
      _.assign($scope, window.user);
      var filterPath = function (string) {
        return string
        .replace(/^\//, '')
        .replace(/(index|default).[a-zA-Z]{3,4}$/, '')
        .replace(/\/$/, '');
      };

      // use the first element that is "scrollable"
      var scrollableElement = function (els) {
        for (var i = 0, argLength = arguments.length; i < argLength; i++) {
          var el = arguments[i],
          $scrollElement = $(el);
          if ($scrollElement.scrollTop() > 0) {
            return el;
          } else {
            $scrollElement.scrollTop(1);
            var isScrollable = $scrollElement.scrollTop() > 0;
            $scrollElement.scrollTop(0);
            if (isScrollable) {
              return el;
            }
          }
        }
        return [];
      };

      var locationPath = filterPath(location.pathname);
      var scrollElem = scrollableElement('html', 'body');

      $('a[href*="#"]').filter('[data-no-jump]').each(function () {
        var thisPath = filterPath(this.pathname) || locationPath;
        if (locationPath === thisPath &&
          (location.hostname === this.hostname || !this.hostname) &&
          this.hash.replace(/#/, '')) {
          var target = this.hash, $target = $(target);
          if (target && $target.length) {
            var targetOffset = $target.offset().top;
            $(this).click(function (e) {
              e.preventDefault();
              $(scrollElem).animate({
                scrollTop: targetOffset
              }, 400, function () {
                location.hash = target;
              });
            });
          }
        }
      });
    }).
    controller('ProfileController', function ($location, $window, $http, $scope, userService, profile) {
      _.assign($scope, window.user);
      var validateVgsHiddenCcInput = _.debounce(function (fakeInput) {
        // Enters dummy input to the div so that the validation engine can properly validate it
        // and show/hide the error prompt if necessary. We need to fill in a value and validate so that
        // validation for other fields on the form can trigger if needed.
        var ccElem = $('#vgs-account-hidden');
        if (ccElem) {
          ccElem.val(fakeInput);
          ccElem.validationEngine('validate');
        }
      }, 100);

      var validateVgsHiddenDateInput = _.debounce(function(fakeInput) {
        var dateElem = $('#vgs-expdate-hidden');
        if (dateElem) {
          dateElem.val(fakeInput);
          dateElem.validationEngine('validate');
        }
      }, 100);

      var secureForm, secureAccountField, secureExpDate;
      var attachVgsFields = function(secureForm) {
        if (!secureForm) {
          return secureForm;
        }
        if (!secureAccountField) {
          secureAccountField = secureForm.field('#vgs-account', {
            type: 'card-number',
            name: 'Account',
            placeholder: '\u25CF\u25CF\u25CF\u25CF \u25CF\u25CF\u25CF\u25CF \u25CF\u25CF\u25CF\u25CF \u25CF\u25CF\u25CF\u25CF',
            autoComplete: 'cc-number',
            validations: ['validCardNumber'],
            css: {
              fontFamily: 'monospace',
              fontSize: '15px',
              '&::placeholder': {
                color: '#c4cfdd'
              }
            }
          });
        }
        if (!secureExpDate) {
          secureExpDate = secureForm.field('#vgs-expdate', {
            type: 'card-expiration-date',
            name: 'ExpirationDate',
            autoComplete: 'cc-exp',
            placeholder: 'MM / YYYY',
            validations: ['validCardExpirationDate'],
            serializers: [(secureForm.SERIALIZERS.separate({monthName: 'Month', yearName: 'Year'}))],
            css: {
              fontFamily: 'monospace',
              fontSize: '15px',
              '&::placeholder': {
                color: '#c4cfdd'
              }
            }
          });
        }

        return secureForm;
      };

      $scope.vgsConfigured = false;
      $scope.initProfile = function() {
        var vgsVaultId = window.vgsVaultId;
        if (window.VGSCollect && vgsVaultId) {
          // create VGS COllect form
          secureForm = window.VGSCollect.create(vgsVaultId, function(formState){
            // listen for event changes so that we can mimic the inline jquery validation
            if (formState) {
              if (formState.Account) {
                var accountElem = $('#vgs-account-hidden');
                var accountState = formState.Account;
                // enter fake input into the div and call the validation engine to validate the input
                if (accountState.isValid) {
                  validateVgsHiddenCcInput('4111111111111111');
                } else if (accountState.isEmpty) {
                  validateVgsHiddenCcInput('');
                } else {
                  validateVgsHiddenCcInput('4141');
                }
                accountElem.toggleClass('focus', accountState.isFocused);
              }
              if (formState.ExpirationDate) {
                var expDateElem = $('#vgs-expdate-hidden');
                var expDateState = formState.ExpirationDate;
                // enter fake input into the div and call the validation engine to validate the input
                if (expDateState.isValid) {
                  var today = new Date();
                  validateVgsHiddenDateInput(today.toDateString());
                } else if (expDateState.isEmpty) {
                  validateVgsHiddenDateInput('');
                } else {
                  validateVgsHiddenDateInput('notvaliddate');
                }
                expDateElem.toggleClass('focus', expDateState.isFocused);
              }
            }
          });
          if (secureForm) {
            $scope.vgsConfigured = true;
            if (!$scope.$$phase) {
              $scope.$apply();
            }
          }
        }

        $scope.showVgs = function() {
          if ($scope.showCreditCardInput && secureForm) {
            secureForm = attachVgsFields(secureForm);
          }
        };

        $scope.displayCreditCardField = function () {
          $scope.showCreditCardInput = true;
          $scope.showCancelButton = true;
          $scope.focusCreditCardInput = true;

          if (secureForm) {
            secureForm = attachVgsFields(secureForm);
          }
        };

        $scope.cancelChanges = function() {
          $scope.showCreditCardInput = false;
          $scope.showCancelButton = false;

          if (secureForm) {
            if (secureAccountField) {
              secureAccountField.delete();
              secureAccountField = null;
            }
            if (secureExpDate) {
              secureExpDate.delete();
              secureExpDate = null;
            }
          }
        };

        $scope.getDatabagForFields = function($fields){
          $.ajax({
            url: '//profile.ngpvan.com/v2/data/' + $scope.id + '/fastaction',
            dataType: 'json'
          }).
          then(function (profile){
            $fields.each(function(){
              var value = profile[this.name];
              if (value) { this.value = value }
            });
          });
        };

        var $fields = $('form.prefill').find('input[name], select[name]');
        if ($fields.length) {
          $scope.getDatabagForFields($fields);
        }

        var profileSendSuccessCallback = function(resp) {
          return $scope.$apply(function(){
            if (resp==null) { return false; }
            $scope.primaryCard = resp.primaryCard;
            $scope.additionalCards = resp.additionalCards;

            $scope.showCreditCardInput=resp.showCreditCardInput;
            $scope.showCancelButton=resp.showCancelButton;
          });
        };

        $scope.submit = function(){
          profile.validate() && profile.send(secureForm, profileSendSuccessCallback);
        };

        var reallyRemove = function(provider){
          var providerName = {
            actionid: 'ActionID'
          };

          provider = providerName[provider] ? providerName[provider] : provider;
          return 'By removing your only login you will be unable to use this FastAction profile from other devices.\n\nAre you sure you want to remove your '+ provider +' login?';
        };

        $scope.removing = {};
        $scope.deauthProvider = function(provider){
          if (_.keys($scope.authProviders).length === 1 && !window.confirm(reallyRemove(provider))) {
            return false;
          }
          $scope.removing[provider] = true;
          $http({
            timeout: 5000,
            method: 'DELETE',
            url: '/api/authProviders/' + provider
          }).
          success(function (data) {
            delete $scope.removing[provider];
            window.user.authProviders = $scope.authProviders = data;
            window.user.isAuthenticated = $scope.isAuthenticated = !!_.keys($scope.authProviders).length;
          })
              .error(function(){
                delete $scope.removing[provider];
              });
        };

        $scope.authProvider = function(e){
          e.preventDefault();
          e.stopPropagation();
          var popup, pop = {
            left: 0,
            top:  0,
            width: 1000,
            height: 640
          };

          if (screen && screen.width && screen.height) {
            pop.top  = ~~((screen.height/2) - (pop.height/2));
            pop.left = ~~((screen.width/2)  - (pop.width/2));
          }

          popup = window.open(e.currentTarget.href, 'authProvider', 'toolbar=0,menubar=0,location=0,status=0,scrollbars=1,resizable=0,' + $.param(pop).replace(/&/g,','));

          (function checkPopup(){
            if (!popup || popup.closed) {
              $window.location.href = $location.path();
            } else {
              setTimeout(checkPopup, 1000/60);
            }
          })();
        };

        $scope.fetchProviders = function(){
          $http({
            cache: false,
            method: 'GET',
            url: '/api/authProviders'
          }).
          success(function (data) {
            if (_.size($scope.authProviders) < _.size(data)) {
              var $fields = $('form').find('input[name], select[name]');
              $scope.getDatabagForFields($fields);
            }
            window.user.authProviders = $scope.authProviders = data;
            window.user.isAuthenticated = $scope.isAuthenticated = !_.isEmpty($scope.authProviders);
          });
        };

        $scope.deleteAccount = function(){
          var $profile = $('.profile-page');
          if (window.confirm('Are you sure you want to delete your FastAction profile?\n\nWe store your encrypted payment information securely so that you can easily donate with a single click.')) {
            $profile.isLoading({
              text: 'Deleting Profile',
              position: 'overlay'
            });
            $http({
              cache: false,
              method: 'DELETE',
              url: '/profile'
            }).
            success(function (data) {
              $profile.isLoading('hide');
              $profile.isLoading({
                text: 'Your FastAction profile has been deleted. Your payment information is no longer stored.',
                position: 'overlay'
              });
              setTimeout(function(){
                window.location.pathname = '/logout';
              }, 1000);
            });
          }
        };
      };
    }).
    controller('HardReloadController', function ($scope, $location, $window) {
      // Force a hard reload
      $window.location.href = $location.path();
    }).
    controller('TransactionsController', function ($scope, $http, userService) {
      _.assign($scope, window.user);
    }).
    controller('IESucks', function ($scope) {
      $scope.loggedin = false;
      $scope.user = {};
    });
})(angular);
