
/* Services */

(function (angular) {

  'use strict';

  angular.module('fastActionApp.services', []).value('version', '0.1').
    service("userService", function ($http, $q) {
      // gets the display name for the provided user
      this.sayMyName = function (user) {
        if (typeof user !== 'undefined') {
          return user.displayName || '';
        }
        return '';
      };

      // returns true if the user has at least one authentication profile
      this.isAuthenticated = function (user) {
        // TODO seems a little fragile, if any other properties or methods are tacked onto profiles object
        return user && user.profiles && !_.isEmpty(user.profiles);
      };

      this.getUserForScope = function (user) {
        var deferred = $q.defer();
        var scope = {
          user: user,
          isAuthenticated: false,
          displayName: '',
          hasDisplayName: false
        };

        var attachDisplayNameToScope = _.bind(function () {
          scope.isAuthenticated = this.isAuthenticated(scope.user);
          scope.displayName = this.sayMyName(scope.user);
          scope.hasDisplayName = !!(scope.displayName.replace(/\s/g, ''));
          deferred.resolve(scope);
        }, this);

        if (_.isEmpty(scope.user)) {
          $http({
            cache: false,
            method: 'GET',
            url: '/api/v1/users'
          }).
            success(function (data) {
              if (data.errors && data.errors[0].code === 'USER_NOT_LOGGED_IN') {
                scope.loggedin = false;
                scope.user = {};
              } else {
                scope.loggedin = true;
                scope.user = data;
              }
              attachDisplayNameToScope();
            }).
            error(function (data) {
              if (data.errors && data.errors[0].code === 'USER_NOT_LOGGED_IN') {
                scope.loggedin = false;
                scope.user = {};
              }
              attachDisplayNameToScope();
            });
        } else {
          attachDisplayNameToScope();
        }
        return deferred.promise;
      };
    }).
    service("profile", function ($http) {
      this.validate = function () {
        return $("#profile form").validationEngine('validate');
      };
      // pass isError=true to show a red banner. passing anything falsy including omitting the parameter entirely shows a green banner
      var showFeedback = function (message, data, status, isError) {
        $.easyNotification({
          text: message,
          parent: '#profile',
          containerClassName: isError ? "alert alert-error error" : "alert alert-success success"
        });
      };

      var resolveCardType = window.resolveCardType = function resolveCardType(accountNumber) {
        if (/^5[1-5]/.test(accountNumber)) { return "mastercard"; }
        if (/^4/.test(accountNumber)) { return "visa"; }
        if (/^3[47]/.test(accountNumber)) { return "amex"; }
        if (/^(6011|65|64[4-9]|622)/.test(accountNumber)) { return "discover"; }
        return "unknown";
      };

      //Special validation method for validation credit card number and types
      window.checkCreditCard = function (field, rules, i, options) {
        if (!field.val().luhnCheck()) { return "Invalid credit card number"; }
        if (resolveCardType(field.val()) === "unknown") { return "Invalid credit card type"; }
      };

      var readOnlyCreditCard = function (updatedProfile) {
        if (updatedProfile && updatedProfile.primaryCard) {
          return {
            primaryCard: updatedProfile.primaryCard,
            additionalCards: updatedProfile.additionalCards,
            showCreditCardInput: false,
            showCancelButton: false,
          };
        }
        return null;
      };

      var sendFormData = function (formData, acctNumber, success) {
        $.ajax({
          type: 'POST',
          url: 'api/v2/Forms/1',
          data: formData,
          success: function (data, status) {
            $("#profile").isLoading("hide");
            // handles successful 200 responses with bodies containing success=true
            if (typeof data.success !== 'undefined' && data.success) {
              showFeedback('Your profile was successfully saved.', data, status, false);
              success(readOnlyCreditCard(data.updatedProfile));
              // handles successful 200 responses with bodies containing success=false
            } else {
              var msg = data.message || 'An unexpected error has occurred. Please try again later or contact our support.';
              showFeedback(msg, data, status, true);
            }
          }.bind(this),
          // handler for errors https://api.jquery.com/jQuery.ajax/
          error: function (xhr, status, error) {
            $("#profile").isLoading("hide");
            showFeedback('An unexpected error has occurred. Please try again later or contact our support.', error, status, true);
          }.bind(this),
          // "finally" callback that gets called no matter what
          complete: function (data) {
            $(window).scrollTop(0);
          },
          headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
          crossDomain: true,
          xhrFields: { withCredentials: true }
        });
      };

      this.send = function (secureForm, success) {
        $("#profile").isLoading({
          text: "Loading",
          position: "overlay"
        });

        var formData = $("#profile form").serialize();
        if (secureForm && secureForm.frames && secureForm.frames.length) {
          // redact number using VGS Collect. The response should have the redacted number
          // so wait for the response to send along with the rest of payload
          secureForm.submit('/post', {
            // opts
          },
            // vgs success callback
            function (status, res) {
              // the HA server does not package the result within a json property
              var data = res.json || res;
              var redactedAccount = data.Account;
              if (redactedAccount) {
                formData += '&Account=' + redactedAccount + '&RedactedAccount=' + redactedAccount;
              }
              var expDate = data.ExpirationDate;
              if (expDate) {
                var year = parseInt(data.ExpirationDate.Year) % 100;
                formData += '&ExpirationMonth=' + ('0' + data.ExpirationDate.Month).slice(-2) + '&ExpirationYear=' + ('0' + year).slice(-2);
              }
              // resolve the vgs post first and then call post to forms
              sendFormData(formData, redactedAccount, success);
            },
            // vgs error callback
            function (errors) {
              // everything is fine, try to at least save the rest of the data
              formData += '&Account=';
              sendFormData(formData, '', success);
            });
        } else {
          sendFormData(formData, $('input[name="Account"]').val(), success);
        }
      };
    });
})(angular);
