/* Directives */
(function(angular) {
    'use strict';
    var app = angular.module('fastActionApp.directives', []);
    app.directive('appVersion', function(version) {
        return function(scope, elm, attrs) {
            elm.text(version);
        };
    });
    app.directive('focusMe', function($timeout, $parse) {
        return {
            //scope: true,   // optionally create a child scope
            link: function(scope, element, attrs) {
                var model = $parse(attrs.focusMe);
                scope.$watch(model, function(value) {
                    if (value === true) {
                        $timeout(function() {
                            element[0].focus();
                        });
                    }
                });
            }
        };
    });

    app.directive('mask', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                $(element).mask(attrs.mask);
            }
        };
    });

    app.directive('placeholder', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                if ($.fn.placeholder) {
                    $(element).placeholder();
                }
            }
        };
    });

    app.directive('validation', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                $(element).validationEngine({
                    maxErrorsPerField: 1
                });
            }
        };
    });

    app.directive('validate', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                element.addClass("validate[" + attrs.validate + "]");
            }
        };
    });

    app.directive('requiredIndicator', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                element.append('<span class="requiredIndicator">' + attrs.requiredIndicator + '</span>');
            }
        };
    });

    app.directive('sticky', function() {
        return {
            restrict: 'A',
            link: function(scope, element, attrs) {
                var navHeight = $("nav.app-nav").height();
                var bannerHeight = $(".people-banner").height();
                var bannerTop = $(".people-banner").position().top;

                //Sticky menu
                $(window).off('scroll.sticky').on('scroll.sticky', function(event) {
                    var top = 0;
                    var marginTop = 100 + navHeight;

                    if ($(window).scrollTop() < bannerHeight) {
                        top = -19 - $(window).scrollTop();
                        marginTop = bannerTop + bannerHeight + navHeight;
                    }

                    $(element).css({
                        top: top,
                        marginTop: marginTop
                    });
                });
            }
        };
    });

})(angular);