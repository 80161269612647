// Declare app level module which depends on filters, and services

var modal = require('./modal'),
  controllers = require('./controllers'),
  directives = require('./directives'),
  services = require('./services'),
  filters = require('./filters'),
  helpers = require('./helpers'),
  directives = require('./directives'),
  mask = require('./libs/jquery.mask'),
  loading = require('./libs/jquery.isloading'),
  validation = require('./libs/jquery.validationEngine'),
  notifications = require('./libs/easy.notification');

(function (angular) {

  'use strict';

  var app = angular.module('fastActionApp', [
    'fastActionApp.controllers',
    'fastActionApp.filters',
    'fastActionApp.services',
    'fastActionApp.directives'
  ]);

  app.config(function ($routeProvider, $locationProvider) {
    $routeProvider.
      when('/', {
        templateUrl: 'partials/front',
        controller: 'FrontController'
      }).
      when('/profile', {
        templateUrl: 'partials/profile',
        controller: 'ProfileController'
      }).
      when('/transactions', {
        templateUrl: 'partials/transactions',
        controller: 'TransactionsController'
      }).
      when('/auth/actionid', {
        templateUrl: 'partials/front',
        controller: 'HardReloadController'
      }).
      when('/auth/actionid_signup', {
        templateUrl: 'partials/front',
        controller: 'HardReloadController'
      }).
      when('/logout', {
        templateUrl: 'partials/logout',
        controller: 'HardReloadController'
      }).
      when('/login', {
        templateUrl: 'partials/front',
        controller: 'IESucks'
      }).
      when('/signup', {
        templateUrl: 'partials/front',
        controller: 'IESucks'
      }).
      otherwise({
        redirectTo: '/'
      });

    $locationProvider.html5Mode(true);
  });

  app.run(function ($rootScope, $location, $anchorScroll, $routeParams) {
    $rootScope.$on('$routeChangeSuccess', function (newRoute, oldRoute) {
      $anchorScroll();
    });
  });

})(angular);
