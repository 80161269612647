
/* Filters */

(function(angular) {

  angular.module('fastActionApp.filters', []).
    filter('interpolate', function (version) {
      return function (text) {
        return String(text).replace(/\%VERSION\%/mg, version);
      };
    });

})(angular);
